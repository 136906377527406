<template>
  <div class="copyright-practice-detail">
    <div class="breadcrumb">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/copyrightService' }"
          ><img
            class="img"
            :src="require('assets/images/disk/disk-operate-prev.png')"
            alt=""
          />
          版权服务</el-breadcrumb-item
        >
        <!-- <el-breadcrumb-item
          :to="{ path: '/copyrightService/copyrightPractice' }"
          >版权实务</el-breadcrumb-item
        >
        <el-breadcrumb-item>自助指引</el-breadcrumb-item> -->
      </el-breadcrumb>
    </div>

    <div class="copyright-practice-detail-content">
      <div class="copyright-practice-detail-content-main">
        <div class="copyright-practice-detail-content-main-picgroup">
          <PictureShowcase :images="imgList" height="380px" />
        </div>
        <div class="copyright-practice-detail-content-main-desc">
          <div class="title">{{ goodsData.productName }}</div>
          <div class="product-info">
            商品编码&nbsp;|&nbsp;{{ goodsData.productCode }}&nbsp;
          </div>
          <div class="block">
            <div class="left">
              <div class="specification">
                <span class="space">{{ getSpecificationName }}</span>
                <div>
                  <Specifications
                    :isShowNeedtime="isShowNeedtime"
                    :isShowUndertaker="isShowUndertaker"
                    :undertaker="undertaker"
                    :needtime="needtime"
                    :productType="goodsData.productType"
                    :specificationList="goodsSpecifications"
                    @handleSelect="handleSelect"
                  />
                </div>
              </div>
              <div class="price">
                <span class="space">价格</span>
                <span class="txt"
                  ><span class="small">￥</span
                  >{{
                    specificationitem.price
                      ? specificationitem.price.toFixed(2)
                      : price.toFixed(2)
                  }}</span
                >
              </div>
            </div>
            <!-- <div class="right">
              <span class="evaltxt">累计评价</span>
              <span class="evalnum">50万+</span>
            </div> -->
          </div>
          <div class="last">
            <span class="busin">服务提供商: 深圳写手智能科技有限公司</span>
            <span class="follow" @click="toFollow" v-show="false">
              <img
                v-show="followed"
                :src="require('assets/images/followed.png')"
                alt=""
              />
              <img
                v-show="!followed"
                :src="require('assets/images/follow.png')"
                alt=""
              />
              <span v-show="followed" class="followed-txt">已关注</span>
              <span v-show="!followed" class="txt">关注</span>
            </span>
          </div>
          <div class="addshop">
            <CopyrightCounter
              :number="sum"
              :productType="goodsData.productType"
              @childByValue="childByValue"
            />
            <div class="shopcarBtn" @click="addToShopcar">立即购买</div>
          </div>
        </div>
      </div>
      <div class="copyright-practice-detail-content-sets">
        <div @click="lick" class="collect">
          <img v-if="!like" :src="require('assets/images/like.png')" alt="" />
          <img
            v-if="like"
            :src="require('assets/images/like-red.png')"
            alt=""
          />
          收藏
        </div>
        <div class="share">
          <img :src="require('assets/images/share.png')" alt="" />
          分享
        </div>
      </div>
      <div class="copyright-practice-detail-content-recommend">为你推荐</div>
      <div class="copyright-practice-detail-content-slidewrap">
        <ShowSlideGroup :item-list="showSlideList" />
      </div>
      <div class="copyright-practice-detail-content-tabs">
        <el-tabs v-model="activeTab" type="card" @tab-click="tabChange">
          <el-tab-pane
            :label="goodsData.productType == 4 ? '文稿服务介绍' : '基本信息'"
            name="first"
          >
            <span class="tabs-title" slot="label">{{
              goodsData.productType == 4 ? '文稿服务介绍' : '基本信息'
            }}</span>
            <div class="goods-tab-item">
              <div v-if="goodsData.productType == 4" class="process_box">
                <div class="process_title">标准流程与耗时</div>
                <div class="process_list">
                  <div class="header">
                    <div class="name_header">阶段</div>
                    <div class="header_item">
                      <div class="directory_header">目录</div>
                      <div class="needtime_header">耗时</div>
                      <div class="coordinate_header">备注</div>
                    </div>
                  </div>
                  <div
                    class="process_item"
                    v-for="(item, index) in processList"
                    :key="index"
                  >
                    <div class="name_box">
                      <div class="name_item">
                        {{ item.name }}
                      </div>
                      <div class="item_name">
                        <div
                          class="children"
                          v-for="(children, index1) in item.children"
                          :key="index1"
                        >
                          <div class="name">
                            {{ children.name }}
                          </div>
                          <div class="needtime">
                            {{ children.needtime }}
                          </div>
                          <div class="coordinate">
                            {{ children.coordinate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                v-else
                class="img"
                :src="
                  $store.state.onlineBasePath + goodsData.productIntroduction
                "
                alt=""
              />
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="goodsData.productType == 4"
            label="知识博主简介"
            name="second"
          >
            <span class="tabs-title" slot="label">知识博主简介</span>
            <div class="goods-tab-item">
              <div class="content">
                <div class="sort-wrap">
                  <div style="display: flex">
                    <!-- <span class="practice-sort">执业等级</span> -->
                    <span
                      :class="sortType == 0 ? 'colorc' : 'colorb'"
                      class="price-sort"
                      @click="sortAndSortType(1)"
                      >价格排序
                      <img
                        class="img"
                        :src="
                          sortType == 0
                            ? require('assets/images/sort-ash.png')
                            : sortType == 1
                            ? require('assets/images/sort-top.png')
                            : require('assets/images/sort-bottom.png')
                        "
                        alt=""
                    /></span>
                    <span
                      :class="sortType1 == 0 ? 'colorc' : 'colorb'"
                      class="add-team-sort"
                      @click="sortAndSortType(2)"
                      >加入团队排序
                      <img
                        class="img"
                        :src="
                          sortType1 == 0
                            ? require('assets/images/sort-ash.png')
                            : sortType1 == 1
                            ? require('assets/images/sort-top.png')
                            : require('assets/images/sort-bottom.png')
                        "
                        alt=""
                    /></span>
                  </div>
                  <div>
                    本团队由{{ undertakerList[0].userId }}于{{
                      undertakerList[0].createTime.substring(0, 10)
                    }}创建，现有团队成员{{ undertakerList.length }}人。
                  </div>
                </div>
                <div class="undertaker-list">
                  <div
                    class="undertaker-list-item"
                    v-for="(item, index) in undertakerList"
                    :key="index"
                  >
                    <span class="head"
                      ><img
                        :src="
                          item.headImg
                            ? item.headImg
                            : require('assets/images/user.png')
                        "
                        @error="imgError(item)"
                        alt=""
                    /></span>
                    <span class="name">{{ item.nickName }}</span>
                    <!-- <span class="level">{{ item.userLevel }}</span> -->
                    <span class="price">{{ item.payPrice }}元/工作时</span>
                    <span class="time">{{
                      item.createTime.substring(0, 10)
                    }}</span>
                    <span class="time">{{ item.occupation }}</span>
                    <span class="time">{{ item.education }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="用户评论" name="third">
            <span class="tabs-title" slot="label"
              >用户评论({{ comment.length }})</span
            >
            <div class="goods-tab-item">
              <div class="sm-goods-evaluate">
                <div class="evaluate-header">
                  <div class="evaluate-header-left">
                    <div class="evaluate-header-li">
                      优秀评论({{ comment.length }}）
                    </div>
                  </div>
                  <div class="evaluate-header-right">
                    <div class="evaluate-percent">99 <span>%</span></div>
                    <div class="evaluate-txt">好评度</div>
                  </div>
                </div>
                <div class="evaluate-content">
                  <ul class="evaluate-content-tab">
                    <div
                      class="evaluate-content-tab-li"
                      :class="all ? 'evaluate-content-tab-li-ac' : ''"
                      @click="handleAllRate"
                    ></div>
                    <div
                      class="evaluate-content-tab-li"
                      :class="good ? 'evaluate-content-tab-li-ac' : ''"
                      @click="Praise"
                    >
                      好评
                    </div>
                    <div
                      class="evaluate-content-tab-li"
                      :class="med ? 'evaluate-content-tab-li-ac' : ''"
                      @click="Medium"
                    >
                      中评
                    </div>
                    <div
                      class="evaluate-content-tab-li"
                      :class="bad ? 'evaluate-content-tab-li-ac' : ''"
                      @click="badpost"
                    >
                      差评
                    </div>
                    <div class="evaluate-content-tab-li">时间排序</div>
                  </ul>
                  <ul class="evaluate-content-li">
                    <li
                      v-for="(v, i) of commentCopy"
                      :key="i"
                      class="evaluate-content-item"
                    >
                      <div class="evaluate-item-avatar">
                        <img
                          :src="
                            v.userheadImg
                              ? v.userheadImg
                              : require('assets/images/user.png')
                          "
                          @error="userheadImg(v)"
                          alt=""
                        />
                        <span v-html="v.nickName"></span>
                      </div>
                      <div class="evaluate-item-content">
                        <el-rate v-model="v.score" :disabled="true"> </el-rate>
                        <p
                          class="evaluate-item-content-text"
                          v-html="v.content"
                        ></p>
                        <ul class="evaluate-item-content-imgs">
                          <li
                            v-for="(u, index) of v.evaluateImgs"
                            :key="index"
                            class="evaluate-item-content-img"
                          >
                            <img
                              :src="$store.state.onlineBasePath + u"
                              alt=""
                            />
                          </li>
                        </ul>
                        <ul class="evaluate-item-content-actionbar">
                          <li
                            class="evaluate-item-content-actionbar-date"
                            v-html="v.createTime"
                          ></li>
                          <li class="evaluate-item-content-actionbar-report">
                            举报
                          </li>
                          <li class="evaluate-item-content-actionbar-fabu">
                            <img
                              :src="require('assets/images/fabu.png')"
                              alt=""
                            />
                            赞(<span>0</span>)
                          </li>
                          <li
                            class="evaluate-item-content-actionbar-reply"
                            @click="replyClick(v)"
                          >
                            <img
                              :src="require('assets/images/reply.png')"
                              alt=""
                            />
                            回复(<span>0</span>)
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <div class="nomessage" v-if="commentCopy.length == 0">
                    <img src="../../../../assets/images/no-message.png" />
                    <div>暂无评价</div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog
      :visible.sync="showProcess"
      :modal="false"
      :close-on-click-modal="false"
      title="选择阶段流程"
      class="select-process"
    >
      <div class="content">
        <div class="top">
          <span class="title">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            />
            函件文稿辅导</span
          >
          <span class="time-sum"
            ><span class="blue">{{ needtime }}</span
            >&nbsp;个工作时</span
          >
        </div>

        <!-- <el-tree
          :data="processList"
          show-checkbox
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
          class="tree"
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <span>{{ data.name }} </span>
            <span>{{ data.needtime }}个工作时 </span>
            <span :title="data.require"> {{ data.require }}</span>
          </span>
        </el-tree> -->

        <div class="checkbox">
          <el-checkbox-group
            v-model="check"
            @change="handleCheckedCitiesChange"
          >
            <div
              class="checkbox-item"
              v-for="(item, index) in processList"
              :key="index"
            >
              <el-checkbox :label="item">
                <div class="check-desc">
                  <span class="process-name">{{ item.name }}</span>
                  <span class="process-explain">{{ item.coordinate }}</span>
                  <span class="process-time">{{ item.needtime }}个工作时</span>
                </div>
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btns">
          <div class="footer-btn sure" @click="sureProcess">确定</div>
          <div class="footer-btn cancel" @click="cancel">取消</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      @open="showUndertakerOpen"
      :visible.sync="showUndertaker"
      :modal="false"
      :close-on-click-modal="false"
      title="选择承办人员（函件文稿辅导团队）"
      class="select-undertaker"
    >
      <div class="content">
        <div class="sort-wrap">
          <span class="name_text"> 姓名 </span>
          <span class="practice-sort">执业等级</span>
          <span
            :class="sortType == 0 ? 'colorc' : 'colorb'"
            class="price-sort"
            @click="sortAndSortType(1)"
            >价格排序
            <img
              class="img"
              :src="
                sortType == 0
                  ? require('assets/images/sort-ash.png')
                  : sortType == 1
                  ? require('assets/images/sort-top.png')
                  : require('assets/images/sort-bottom.png')
              "
              alt=""
            />
          </span>
          <span
            :class="sortType1 == 0 ? 'colorc' : 'colorb'"
            class="add-team-sort"
            @click="sortAndSortType(2)"
            >加入团队排序
            <img
              class="img"
              :src="
                sortType1 == 0
                  ? require('assets/images/sort-ash.png')
                  : sortType1 == 1
                  ? require('assets/images/sort-top.png')
                  : require('assets/images/sort-bottom.png')
              "
              alt=""
          /></span>
        </div>
        <div class="undertaker-list">
          <div
            class="undertaker-list-item"
            v-for="(item, index) in dialogUndertakerList"
            :key="index"
            @click="handleClick(item)"
          >
            <span class="head"
              ><img
                :src="
                  item.headImg
                    ? item.headImg
                    : require('assets/images/user.png')
                "
                @error="imgError(item)"
                alt=""
            /></span>
            <span class="name">{{ item.nickName }}</span>
            <!-- <span class="level">{{ item.userLevel }}</span> -->
            <span class="level">{{ item.occupation }}</span>
            <span class="price">{{ item.payPrice }}元/工作时</span>
            <span class="time">{{ item.createTime.substring(0, 10) }}</span>
          </div>
        </div>
      </div>
      <div class="footer">
        <el-pagination
          background
          layout="prev,pager,next"
          prev-text="上一页"
          next-text="下一页"
          :page-size="undertakerPageSize"
          :total="undertakerPageCount"
          :current-page="undertakerPageNum"
          @current-change="undertakerCurrentChange"
        ></el-pagination>
      </div>
    </el-dialog>

    <!-- 立即购买弹窗 -->
    <el-dialog
      width="30%"
      :close-on-click-modal="false"
      title="确认订单"
      :visible.sync="addShopDialog"
    >
      <div class="shop_box">
        <div class="shop_left">
          <img
            class="img"
            :src="
              imgList
                ? $store.state.onlineBasePath + imgList[0].fileUrl
                : require('assets/images/no-shop-img100.png')
            "
          />
        </div>
        <div class="shop_right">
          <div class="txet_top">购买{{ goodsData.productName }}</div>
          <div class="text_bottom">
            <div class="text_left">
              单价: ¥{{
                specificationitem.price ? specificationitem.price : price
              }}
            </div>
            <div class="text_center">数量: {{ sum }}</div>
            <div class="text_right">
              ¥
              {{
                specificationitem.price
                  ? (specificationitem.price * sum).toFixed(2)
                  : price.toFixed(2)
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="coupons_box">
        <div class="coupons_top">
          <i class="icon"></i>
          优惠码

          <el-input
            maxlength="6"
            @input="codeHandelInput(codeInput)"
            v-model="codeInput"
            placeholder="选填"
          ></el-input>
        </div>

        <div class="coupons_bottom" v-if="discountObj == null">
          <div class="text_left">无优惠</div>
          <div class="text_right">- ¥ 0.00</div>
        </div>

        <div class="coupons_bottom" v-else>
          <div class="text_left">{{ discountObj.codeName }}</div>
          <div class="text_right">
            - ¥
            {{
              specificationitem.price
                ? (
                    specificationitem.price * sum -
                    discountObj.discountPrice * sum
                  ).toFixed(2)
                : (price * (1 - discountObj.discountValue)).toFixed(2)
            }}
          </div>
        </div>
      </div>

      <div class="payment_text" v-if="discountObj == null">
        应付款:
        <span class="text"
          >¥
          {{
            specificationitem.price
              ? (specificationitem.price * sum).toFixed(2)
              : price.toFixed(2)
          }}</span
        >
      </div>

      <div class="payment_text" v-else>
        应付款:
        <span class="text"
          >¥
          {{
            specificationitem.price
              ? (discountObj.discountPrice * sum).toFixed(2)
              : (price * discountObj.discountValue).toFixed(2)
          }}</span
        >
      </div>

      <div slot="footer" class="dialog-footer" v-if="discountObj == null">
        <div class="payment_text">
          应付款:
          <span class="text"
            >¥
            {{
              specificationitem.price
                ? (specificationitem.price * sum).toFixed(2)
                : price.toFixed(2)
            }}
          </span>
        </div>
        <el-button type="primary" @click="submitOrder">提交订单</el-button>
      </div>

      <div slot="footer" class="dialog-footer" v-else>
        <div class="payment_text">
          应付款:
          <span class="text"
            >¥
            {{
              specificationitem.price
                ? (discountObj.discountPrice * sum).toFixed(2)
                : (price * discountObj.discountValue).toFixed(2)
            }}
          </span>
        </div>
        <el-button type="primary" @click="submitOrder">提交订单</el-button>
      </div>
    </el-dialog>

    <!-- 扫码支付 -->
    <el-dialog
      title="扫码支付"
      :close-on-click-modal="false"
      :visible.sync="paymentPopup"
      @close="handleClosePaymentPopup"
      class="payment"
    >
      <div class="content">
        <div class="notes">请使用微信扫码下方二维码完成支付</div>
        <div class="imgBox">
          <img :src="qrCodeurl.codeImg" alt="" />
        </div>
        <!-- <el-button type="primary" @click="submitOrder">提交订单</el-button> -->
        <div class="price" v-if="discountObj == null">
          应付款：<span
            >￥<span>{{
              specificationitem.price
                ? (specificationitem.price * sum).toFixed(2)
                : price.toFixed(2)
            }}</span></span
          >
        </div>
        <div class="price" v-else>
          应付款：<span
            >￥<span>{{
              specificationitem.price
                ? (discountObj.discountPrice * sum).toFixed(2)
                : (price * discountObj.discountValue).toFixed(2)
            }}</span></span
          >
        </div>
      </div>
    </el-dialog>

    <!-- 支付成功 -->
    <el-dialog
      title="提示"
      :close-on-click-modal="false"
      :visible.sync="paymentSuccessPopup"
      class="paymentSuccess"
    >
      <div class="content">支付成功,是否前去订单中心</div>
      <div class="footer">
        <div
          class="footer-btn"
          @click="
            paymentSuccessPopup = false
            paymentPopup = false
            addshopDialog = false
            $router.push({
              name: 'OrderCenter',
            })
          "
        >
          确定
        </div>
        <div
          class="footer-btn"
          @click="
            paymentSuccessPopup = false
            paymentPopup = false
            addshopDialog = false
          "
        >
          取消
        </div>
      </div>
    </el-dialog>

    <!-- 回复 -->
    <el-dialog
      :title="'回复@' + replyinfo.nickName"
      :close-on-click-modal="false"
      :visible.sync="replyDialog"
      @close="handleClosePaymentPopup"
      class="reply"
    >
      <div class="content">
        <div class="textarea_box">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="发布你的回复"
            v-model="textarea"
          >
          </el-input>
        </div>

        <div class="btn">
          <el-button type="primary" round>回复</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchID,
  searchFile,
  searchSpecification,
  orderAdd,
  payOtherOrderPay,
  PaywechatOrderQueryQrcode,
  AddCopyrightPractice,
  page,
  FindProcess,
  FindPromoter,
  commentList,
  addOrDel,
  findDiscount,
  bind,
  findDiscountInfoByCode,
} from 'api/copyrightService'
import PictureShowcase from 'components/copyright-service/PictureShowcase'
import CopyrightCounter from 'components/copyright-service/CopyrightCounter'
import ShowSlideGroup from 'components/copyright-service/showSlideGroup/ShowSlideGroup'
import Specifications from 'components/copyright-service/specifications/Specifications'
export default {
  name: 'CopyrightPracticeDetail',

  data() {
    return {
      // 优惠码输入框
      codeInput: '',
      // 回复用户信息
      replyinfo: '',
      // 回复弹窗
      replyDialog: false,
      // 回复内容
      textarea: '',
      productInfo: null,
      imgList: false,
      showSlideList: [],
      specificationLang: [
        {
          type: 1,
          name: '规格',
        },
        {
          type: 2,
          name: '等级',
        },
        {
          type: 3,
          name: '版本',
        },
      ],
      specificationType: 1,
      followed: false,
      activeTab: 'first',
      stars: 5,
      showProcess: false,
      // 选择阶段流程数组
      processList: [],
      // 选中流程id数组
      projectdetailIds: [],
      // 工时
      manHour: '',
      // 选中流程数组工时
      needtime: 0,
      showUndertaker: false,
      // 选择承办人员数组
      dialogUndertakerList: [
        {
          userId: 0,
          createTime: '2022-03-08T15:32:01',
          nickName: 0,
          userLevel: 0,
          payPrice: 0,
          occupation: 0,
          education: 0,
        },
      ],
      undertakerList: [
        {
          userId: 0,
          createTime: '2022-03-08T15:32:01',
          nickName: 0,
          userLevel: 0,
          payPrice: 0,
          occupation: 0,
          education: 0,
        },
      ],
      // 选中承办人
      undertaker: 1,
      // 价格
      price: 0,
      undertakerPageSize: 6,
      undertakerPageCount: '',
      undertakerPageNum: 1,
      // 支付成功弹窗
      paymentSuccessPopup: false,
      // 扫码弹窗
      paymentPopup: false,
      // 商品详情
      goodsData: {},
      // 商品规格
      goodsSpecifications: [],
      // 选中的规格
      specificationitem: {},
      // 确认订单弹窗
      addShopDialog: false,
      // 商品数量
      sum: 1,
      // 商品id
      id: '',
      // 订单 oid
      oid: '',
      // 二维码URL
      qrCodeurl: {},
      //定时器
      timer: null,
      //是否收藏
      like: false,
      //评论内容
      comment: [],
      //评论副本
      commentCopy: [],
      //评价类型标记
      all: true,
      good: false,
      med: false,
      bad: false,
      //无评价
      noinfo: false,
      //
      sortType: 0,
      sortType1: 0,
      sort: '',
      // 全选
      checkAll: false,
      // 全选状态
      isIndeterminate: false,
      check: [],
      isShowNeedtime: true,
      isShowUndertaker: true,
      discountObj: '',
    }
  },
  methods: {
    // 监听优惠码输入框
    codeHandelInput(val) {
      if (val.length == 6) {
        this.FindDiscountInfoByCode(val)
      }
    },

    async FindDiscountInfoByCode(code) {
      const data = {
        productId: this.id,
        specificationId: this.specificationitem.id,
        code,
      }

      const res = await findDiscountInfoByCode(data)
      if (res.code === 200) {
        this.addShopDialog = true
        this.discountObj = res.data
        this.codeInput = res.data.code
      } else if (res.code === 2023) {
        this.addShopDialog = true
        this.discountObj = res.data
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },

    //  绑定推荐码
    async bindingCode(val) {
      const data = {
        code: val,
      }
      const res = await bind(data)
      if (res.code == 200) {
        this.getDiscount(val)
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },

    // 回复
    replyClick(val) {
      this.replyDialog = true
      this.replyinfo = val
      this.textarea = ''
    },
    // 选中
    handleCheckedCitiesChange(value) {
      let sum = 0
      this.check.forEach((item) => {
        sum = sum + Number(item.needtime)
      })
      this.needtime = sum
      let checkedCount = value.length
      this.checkAll = checkedCount === this.processList.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.processList.length
    },
    // 全选
    handleCheckAllChange(val) {
      let arr = this.processList.map((item, index) => {
        return item
      })
      this.check = val ? arr : []
      let sum = 0
      this.check.forEach((item) => {
        sum = sum + Number(item.needtime)
      })
      this.needtime = sum
      this.isIndeterminate = false
    },
    //
    handleAllRate() {
      this.commentCopy = this.comment
      this.all = true
      this.good = false
      this.med = false
      this.bad = false
      this.noinfo = false
      if (this.commentCopy.length == 0) {
        this.noinfo = true
      }
    },
    //好评
    Praise() {
      this.all = false
      this.good = true
      this.med = false
      this.bad = false
      this.noinfo = false
      this.commentCopy = []
      for (var a of this.comment) {
        if (a.score == 5) {
          this.commentCopy.push(a)
        }
      }
      if (this.commentCopy.length == 0) {
        this.noinfo = true
      }
    },
    //中评
    Medium() {
      this.all = false
      this.good = false
      this.med = true
      this.bad = false
      this.noinfo = false
      this.commentCopy = []
      for (var a of this.comment) {
        if (a.score != 5 && a.score != 1) {
          this.commentCopy.push(a)
        }
      }
      if (this.commentCopy.length == 0) {
        this.noinfo = true
      }
    },
    //差评
    badpost() {
      this.all = false
      this.good = false
      this.med = false
      this.bad = true
      this.noinfo = false
      this.commentCopy = []
      for (var a of this.comment) {
        if (a.score == 1 || a.score == 0) {
          this.commentCopy.push(a)
        }
      }
      if (this.commentCopy.length == 0) {
        this.noinfo = true
      }
    },
    //获取评论列表
    async getcommentList() {
      let data = {
        productId: this.$route.params.id,
      }
      let res = await commentList(data)
      if (res.code == 200) {
        this.comment = res.data
        this.commentCopy = this.comment
        if (this.comment.length == 0) {
          this.noinfo = true
        }
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },
    //收藏商品
    async lick() {
      let data = {
        productId: this.id,
      }
      let res = await addOrDel(data)
      if (res.code == 200) {
        this.like = !this.like
      }
    },
    // 获取商品列表
    async getShowSlideList(type) {
      const data = {
        page: 1,
        limit: 10,
        type,
      }

      const res = await page(data)
      if (res.code === 200) {
        // console.log(res.data);
        this.showSlideList = [...this.showSlideList, ...res.data.data]
      } else {
        this.$message({
          type: 'error',
          message: '请求失败',
        })
      }
    },

    // 根据ID查询商品
    async goodsdata(id) {
      const params = {
        productId: id,
      }
      // form.append("");
      let res = await searchID(params)
      // console.log(res);
      if (res.code === 200) {
        this.goodsData = res.data
        res.data.productType != 4
          ? this.goodsspecifications(id)
          : (this.findProcess(id), this.findPromoter(id))
        if (this.goodsData.collectionStatus) {
          this.like = true
        } else {
          this.like = false
        }
      } else {
        this.$message({
          type: 'error',
          message: '获取商品信息失败',
        })
      }
    },
    // 搜索查询商品文件
    async goodsfils(id) {
      const data = {
        productId: id,
      }
      // JSON.stringify(data)
      const res = await searchFile(data)
      // console.log(res);
      if (res.code === 200) {
        this.imgList = res.data
      } else {
        this.$message({
          type: 'error',
          message: '获取商品图片失败',
        })
      }
    },

    // 搜索查询商品规格
    async goodsspecifications(id) {
      // productType 4 取消请求
      this.goodsSpecifications = []
      const data = {
        productId: id,
        name: '',
      }

      const res = await searchSpecification(data)
      // console.log(res);

      if (res.code === 200) {
        this.specificationitem = res.data[0]
        this.goodsSpecifications = res.data
      } else {
        this.$message({
          type: 'error',
          message: '获取商品规格失败',
        })
      }
    },

    //授权生成支付二维码
    async QrCode() {
      let that = this
      const data = {
        oid: this.oid,
      }

      const res = await payOtherOrderPay(data)

      if (res.code === 200) {
        this.paymentPopup = true

        // console.log(res.data);
        this.qrCodeurl = res.data
        this.timer = window.setInterval(function () {
          that.wechatOrderQueryQrcode()
        }, 1500)
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },

    // 调用微信支付的查询订单接口
    async wechatOrderQueryQrcode() {
      const data = {
        oid: this.oid,
      }

      const res = await PaywechatOrderQueryQrcode(data)
      if (res.code == 200) {
        window.clearInterval(this.timer)
        if (this.goodsData.productType == 4) {
          this.$router.push({
            name: 'AgencyEntrustment',
          })
        } else {
          this.paymentSuccessPopup = true
        }
      }
    },

    // 新增订单
    async submitOrder() {
      if (this.goodsData.productType == 4) {
        return this.addCopyrightPractice()
      }
      const data = {
        productId: this.id,
        productSpecificationId: this.specificationitem.id,
        quantity: this.sum,
      }
      // console.log(data);
      const res = await orderAdd(data)
      // console.log(res);
      if (res.code === 200) {
        this.oid = res.data
        this.QrCode()

        // this.paymentSuccessPopup = true;
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },

    // 新增版权实务订单
    async addCopyrightPractice() {
      const data = {
        productId: this.id,
        projectdetailIds: this.projectdetailIds,
        handleId: this.undertaker.userId,
      }
      const res = await AddCopyrightPractice(data)
      if (res.code === 200) {
        this.oid = res.data
        this.QrCode()

        // this.paymentSuccessPopup = true;
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },

    // 获取版权实务规格
    async findProcess(id) {
      let form = new FormData()
      form.append('productId', id)
      this.specificationitem.price ? (this.specificationitem.price = 0) : false
      this.isShowNeedtime = true
      this.isShowUndertaker = true
      this.needtime = 0
      this.undertaker = 1
      this.goodsSpecifications = []
      const res = await FindProcess(form)
      if (res.code !== 200) {
        return this.$message({
          type: 'error',
          message: res.message,
        })
      }
      this.goodsSpecifications = [
        { name: '选择阶段流程', title: '选择阶段流程' },
        { name: '选择承办人员', title: '选择承办人员' },
      ]
      this.manHour = res.data.manHour
      this.processList = res.data.projectdetailList
      // this.undertakerList = res.data.teamUserList;
    },
    // 获取版权实务承办人
    async findPromoter(id, sort, sortType, limit) {
      const data = {
        productId: id,
        limit: limit ? limit : 100,
        page: this.undertakerPageNum,
        sort,
        sortType,
      }

      const res = await FindPromoter(data)
      if (res.code !== 200) {
        return this.$message({
          type: 'error',
          message: res.message,
        })
      }

      this.undertakerPageCount = res.data.total
      if (this.showUndertaker) {
        this.dialogUndertakerList = res.data.data
      } else {
        this.undertakerList = res.data.data
      }
    },

    // 商品数量
    childByValue(sum) {
      this.sum = sum
    },

    // 选中的承办人
    handleClick(item) {
      this.price = this.needtime != 0 ? item.payPrice * this.needtime : 0
      this.undertaker = item
      this.showUndertaker = false
    },

    //关注
    toFollow() {
      this.followed = !this.followed
    },
    //选择规格
    handleSelect(item) {
      // console.log(item);
      this.specificationitem = item

      if (item.title == '选择阶段流程') {
        this.showProcess = true
      }
      if (item.title == '选择承办人员') {
        this.showUndertaker = true
        this.findPromoter(this.id, '', '', 6)
      }
    },
    // 立即购买
    addToShopcar() {
      if (this.goodsData.productType == 4) {
        this.$store.commit('CHECK_USER_OPTIONS', {
          afterCheck: () => {
            if (this.goodsData.productType == 4) {
              if (this.needtime == 0 && this.undertaker == 1) {
                this.isShowUndertaker = false
                this.isShowNeedtime = false
                return
              }
              if (this.undertaker == 1) {
                this.isShowUndertaker = false
                return
              }
              if (this.needtime == 0) {
                this.isShowNeedtime = false
                return
              }
            }
            this.getDiscount()
          },
        })
      } else {
        this.getDiscount()
      }
    },
    // 获取优惠
    async getDiscount(code) {
      // let formData = new FormData();
      // formData.append("productId", this.id);
      const data = {
        productId: this.id,
        specificationId: this.specificationitem.id,
        code,
      }
      const res = await findDiscount(data)
      if (res.code === 200) {
        this.addShopDialog = true
        this.discountObj = res.data
        this.codeInput = res.data.code
      } else if (res.code === 2023) {
        this.addShopDialog = true
        this.discountObj = res.data
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },
    tabChange() {},
    //确认流程选择
    sureProcess() {
      let sum = 0
      let arr = []
      this.check.forEach((item) => {
        arr.push(item.projectdetailId)
        sum = sum + Number(item.needtime)
      })

      this.projectdetailIds = arr
      this.needtime = sum
      this.cancel()
    },
    //取消弹框
    cancel() {
      this.price = this.undertaker.payPrice
        ? this.undertaker.payPrice * this.needtime
        : 0
      this.showProcess = false
    },
    undertakerCurrentChange(val) {
      this.undertakerPageNum = val
      this.findPromoter(
        this.id,
        this.sort,
        this.sort == 1
          ? this.sortType == 0
            ? ''
            : this.sortType
          : this.sortType1 == 0
          ? ''
          : this.sortType1,
        6
      )
    },
    // 关闭支付弹窗停止定时器
    handleClosePaymentPopup() {
      // console.log(1);
      window.clearInterval(this.timer)
    },

    userheadImg(item) {
      item.userheadImg = require('assets/images/user.png')
    },
    imgError(item) {
      item.headImg = require('assets/images/user.png')
    },

    // sortType 1升序 2降序
    // sort 排序 1价格 2时间
    sortAndSortType(sort) {
      this.sort = sort
      if (sort == 1) {
        this.sortType = ++this.sortType
        if (this.sortType == 3) {
          this.findPromoter(this.id, '', '', this.showUndertaker ? 6 : 100)
          this.sortType = 0
          return
        }
        this.findPromoter(
          this.id,
          1,
          this.sortType,
          this.showUndertaker ? 6 : 100
        )
      } else {
        this.sortType1 = ++this.sortType1
        if (this.sortType1 == 3) {
          this.findPromoter(this.id, '', '', this.showUndertaker ? 6 : 100)
          this.sortType1 = 0
          return
        }
        this.findPromoter(
          this.id,
          2,
          this.sortType1,
          this.showUndertaker ? 6 : 100
        )
      }
    },
    //
    showUndertakerOpen() {
      this.findPromoter(this.id, '', '', 6)
    },
  },
  computed: {
    getSpecificationName() {
      return this.specificationLang.filter(
        (item) => item.type === this.specificationType
      )[0].name
    },
  },
  components: {
    PictureShowcase,
    CopyrightCounter,
    ShowSlideGroup,
    Specifications,
  },

  watch: {
    $route(to, from) {
      this.sum = 1
      this.getcommentList()
      this.getShowSlideList(1)
      this.getShowSlideList(2)
      this.activeTab = 'first'
      this.id = this.$route.params.id
      this.goodsdata(this.$route.params.id)
      this.goodsfils(this.$route.params.id)
    },
  },

  created() {
    this.sum = 1
    this.getcommentList()
    this.getShowSlideList(1)
    this.getShowSlideList(2)
    this.id = this.$route.params.id
    this.goodsdata(this.$route.params.id)
    this.goodsfils(this.$route.params.id)
  },

  beforeDestroy() {
    //页面销毁前，清除定时器
    window.clearInterval(this.timer) //清除timer定时器
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__header {
  background: #f7f7f7;
  border-bottom: 1px solid #ff6900;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border: none;
  background-color: #ff6900;
}
::v-deep
  .el-tabs--card
  > .el-tabs__header
  .el-tabs__item.is-active
  .tabs-title {
  color: #fff;
}
.goods-tab-item {
  line-height: 1.5;
  width: 100%;
  .process_box {
    background-color: #f7f7f7;
  }

  .process_title {
    text-align: center;
    padding: 50px 0;
    font-weight: 800;
    font-size: 30px;
  }

  .process_list {
    width: 900px;
    margin: 0 auto;
    border-top: 1px solid #ccc;
    border-left: 2px solid #ccc;
    border-bottom: 1px solid #ccc;

    .header {
      font-weight: 800;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
      border-right: 2px solid #ccc;

      .name_header {
        text-align: center;
        width: 100px;
      }

      .header_item {
        display: flex;
        flex: 1;
        .directory_header {
          width: 180px;
          padding: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 2px solid #ccc;
        }

        .needtime_header {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          padding: 20px;
          border-left: 2px solid #ccc;
          border-right: 2px solid #ccc;
        }

        .coordinate_header {
          display: flex;
          justify-content: center;
          padding: 0 0 0 20px;
          align-items: center;
          flex: 1;
        }
      }
    }

    .process_item {
      box-sizing: border-box;

      .name_box {
        border-bottom: 1px solid #ccc;
        border-top: 1px solid #ccc;
        display: flex;
        align-items: center;
        .name_item {
          width: 100px;
          text-align: center;
        }

        .item_name {
          flex: 1;

          .children {
            display: flex;
            border-bottom: 2px solid #ccc;
            &:last-child {
              border-bottom: 0px solid #ccc;
            }
            .name {
              border-left: 2px solid #ccc;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 20px;
              width: 180px;
            }

            .needtime {
              border-left: 2px solid #ccc;
              border-right: 2px solid #ccc;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 20px;
              width: 50px;
            }

            .coordinate {
              border-right: 2px solid #ccc;
              line-height: 1.5;
              padding: 20px;
              flex: 1;
            }
          }
        }
      }
    }
  }
  .img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  .content {
    .sort-wrap {
      display: flex;

      .colorb {
        color: #ff6900;
      }
      .colorc {
        color: #000;
      }

      .price-sort,
      .add-team-sort {
        display: flex;
        align-items: center;
        cursor: pointer;
        .img {
          margin-left: 5px;
          width: 10px;
          height: 15px;
        }
      }
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      span {
        margin-right: 40px;
      }
    }

    .undertaker-list {
      .undertaker-list-item {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .head {
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }

        span {
          &:nth-child(1) {
            width: 80px;
            margin-right: 20px;
          }
          &:nth-child(n + 2) {
            flex: 1;
          }
        }
      }
    }
  }

  .footer {
    margin-top: 40px;
    text-align: center;
  }
}

::v-deep .el-tabs__content {
  padding-bottom: 50px;
}
::v-deep .el-dialog__wrapper.select-process .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 700px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  .el-dialog__header {
    padding: 10px 9px 8px;
    background: #f4f6f7;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: #393939;
    }
    .el-dialog__headerbtn {
      top: 12px;
      right: 12px;
    }
  }
  .el-dialog__body {
    padding: 19px 10px 30px 31px;
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    .content {
      flex: 1;
      .top {
        @include flex-between;
        margin-right: 21px;
        font-size: 14px;
        font-weight: 500;
        color: #333;

        .title {
          .el-checkbox {
            margin-right: 15px;
          }
        }
        .time-sum {
          .blue {
            color: #ff6900;
          }
        }
      }
      .checkbox {
        flex: 1;
        margin-top: 22px;
        overflow-y: scroll;

        .el-checkbox {
          width: 100%;

          .el-checkbox__label {
            width: 90%;
          }
        }

        &::-webkit-scrollbar {
          width: 1px;
          height: 48px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ff6900;
        }
        &-item {
          @include flex-start;
          &:first-child ~ .checkbox-item {
            margin-top: 20px;
          }
          .check-input {
            width: 15px;
            height: 14px;
          }
          .check-desc {
            @include flex-between;
            flex: 1;
            margin-left: 9px;

            .process-explain {
              text-align: center;
            }

            span {
              font-size: 14px;
              font-weight: 500;
              color: #333333;

              &:nth-child(1) {
                flex: 170%;
              }
              &:nth-child(2) {
                flex: 95%;
              }
              &:nth-child(3) {
                flex: 70%;
                text-align: left;
              }
            }
          }
        }
      }

      .custom-tree-node {
        display: flex;
        span {
          width: 180px;
        }
      }

      .tree {
        .el-tree-node {
          .el-tree-node__children {
            .is-expanded {
              .el-tree-node__content {
                padding-left: 0 !important;
              }
            }
          }
        }
      }
    }
    .footer {
      @include flex-between(flex-end);
      .footer-btns {
        @include flex-start;
        margin-top: 31px;
        margin-right: 21px;
        .footer-btn {
          @include noselect;
          width: 72px;
          height: 41px;
          border-radius: 3px;
          text-align: center;
          line-height: 41px;
          cursor: pointer;
          &.sure {
            background: #4c8cff;
            border: 1px solid #ff6900;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
          &.cancel {
            margin-left: 12px;
            background: #ffffff;
            border: 1px solid #cecece;
            font-size: 16px;
            font-weight: 500;
            color: #999999;
          }
        }
      }
    }
  }
}
::v-deep .el-dialog__wrapper.select-undertaker .el-dialog {
  @include flex-center(column);
  justify-content: normal;
  align-items: normal;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  width: 537px;
  height: 473px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  .el-dialog__header {
    padding: 10px 9px 8px;
    background: #f4f6f7;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 500;
      color: #393939;
    }
    .el-dialog__headerbtn {
      top: 12px;
      right: 12px;
    }
  }
  .el-dialog__body {
    padding: 20px 30px 30px;
    @include flex-center(column);
    justify-content: normal;
    align-items: normal;
    flex: 1;
    .content {
      flex: 1;
      .sort-wrap {
        .colorb {
          color: #ff6900;
        }
        .colorc {
          color: #000;
        }
        .price-sort,
        .add-team-sort {
          display: flex;
          cursor: pointer;
          .img {
            height: 15px;
            width: 10px;
          }
        }
        @include flex-start;

        .name_text {
          width: 120px;
          text-align: center;
        }

        .practice-sort {
          margin-left: 30px;
        }

        .price-sort {
          margin-left: 65px;
        }

        .add-team-sort {
          margin-left: 30px;
        }
      }
      .undertaker-list {
        margin-top: 29px;
        &-item {
          &:hover {
            background-color: #f5f7fa;
          }

          cursor: pointer;
          @include flex-between;
          &:first-child ~ .undertaker-list-item {
            margin-top: 20px;
          }
          span {
            font-size: 14px;
            font-weight: 500;
            color: #333333;

            &:nth-child(1) {
              width: 30px;
              margin-right: 20px;
            }
            &:nth-child(n + 2) {
              flex: 1;
            }
          }
          .head {
            width: 30px;
            height: 30px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .footer {
      @include noselect;
      margin: 22px auto 0px;
      .el-pagination {
        button {
          width: 80px !important;
        }
      }
    }
  }
}
.copyright-practice-detail {
  @include nodrag;
  background-color: #fff;
  .breadcrumb {
    @include flex-start;
    padding-left: 51px;
    height: 40px;
    background-color: #f3f3f3;

    ::v-deep .el-breadcrumb__inner {
      display: flex;
      align-items: center;
      .img {
        display: inline-block;
        margin-right: 10px;
        width: 10px;
        height: 15px;
      }
    }
  }
  &-content {
    padding: 0 40px;
    &-main {
      @include flex-start;
      align-items: normal;
      margin-top: 20px;
      &-picgroup {
        width: 300px;
        margin-left: 10px;
      }
      &-desc {
        flex: 1;
        margin-left: 30px;
        .title {
          margin: 15px 0;
          font-size: 18px;
          font-weight: bold;
          color: #666666;
        }
        .product-info {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
        .block {
          @include flex-start;
          margin-top: 20px;
          padding: 15px 20px;
          background-color: #f3f3f3;
          position: relative;
          // &::before {
          //   content: "";
          //   position: absolute;
          //   left: 532px;
          //   top: calc(50% - 26px);
          //   width: 1px;
          //   height: 52px;
          //   background-color: #dcdcdc;
          // }
          .left {
            flex: 1;
            .specification .space,
            .price .space {
              display: inline-block;
              width: 60px;
              text-align-last: justify;
              text-align: justify;
              margin-top: 10px;
              margin-right: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }
            .specification {
              @include flex-start;
              align-items: normal;
              > div {
                flex: 1;
              }
              .txt {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
              }
            }
            .price {
              margin-top: 10px;
              .txt {
                .small {
                  font-size: 12px;
                }
                font-size: 15px;
                font-weight: 500;
                color: #eb441e;
              }
            }
          }
          .right {
            @include flex-center(column);
            margin-left: 20px;
            font-size: 14px;
            font-weight: 500;
            .evaltxt {
              white-space: nowrap;
              color: #666666;
            }
            .evalnum {
              margin-top: 10px;
              color: $color-text-active;
            }
          }
        }
        .last {
          @include flex-between;
          margin: 10px 0;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          .follow {
            cursor: pointer;
          }
          .follow {
            user-select: none;
            span {
              margin-left: 5px;
            }
            .followed-txt {
              color: #666666;
            }
            .txt {
              color: $color-text-active;
            }
          }
        }
        .addshop {
          @include flex-start;
          margin-top: 68px;
          .shopcarBtn {
            width: 160px;
            height: 48px;
            margin-left: 10px;
            background: #eb441e;
            border-radius: 2px;
            text-align: center;
            line-height: 48px;
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
    &-sets {
      @include noselect;
      @include flex-start;
      margin-top: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      cursor: pointer;
      .collect {
        margin-right: 20px;
      }
      .collect,
      .share {
        @include flex-start;
        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
    &-recommend {
      height: 41px;
      padding-left: 14px;
      margin-top: 40px;
      font-size: 14px;
      font-weight: bold;
      color: #666666;
      line-height: 41px;
      background-color: #f7f7f7;
    }
    &-slidewrap {
      margin-top: 20px;
    }
    &-tabs {
      margin-top: 50px;
      .tabs-title {
        color: #333333;
      }
      .sm-goods-evaluate {
        width: 100%;
      }
      .evaluate-header {
        height: 116px;
        display: flex;
      }
      .evaluate-header-left {
        width: 500px;
        display: flex;
        flex-wrap: wrap;
      }

      .evaluate-header-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .evaluate-header-li {
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 0 9px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        height: 22px;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        cursor: pointer;
      }

      .evaluate-percent {
        position: relative;
        width: 90px;
        height: 70px;
        font-size: 62px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ff6900;
      }

      .evaluate-percent span {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 16px;
      }

      .evaluate-txt {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 500;
        color: #444444;
      }

      .evaluate-content {
        width: 100%;
      }

      .evaluate-content-tab {
        display: flex;
        width: 100%;
        height: 37px;
        line-height: 37px;
        background: #f9fbff;
        border: 1px solid #e9f2ff;
        padding: 0 20px;
        box-sizing: border-box;
      }

      .evaluate-content-tab-li {
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        cursor: pointer;
      }

      .evaluate-content-tab-li.evaluate-content-tab-li-ac {
        color: #ff6900;
      }

      .evaluate-content-tab-li + .evaluate-content-tab-li {
        margin-left: 40px;
      }

      .evaluate-content-tab-li:last-child {
        margin-left: auto;
      }

      .evaluate-content-li {
        width: 100%;
      }

      .nomessage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 600px;
      }

      .evaluate-content-item {
        display: flex;
        margin-top: 30px;
      }

      .evaluate-item-avatar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
      }

      .evaluate-item-avatar img {
        width: 71px;
        height: 71px;
        border-radius: 50%;
      }

      .evaluate-item-avatar span {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #444444;
      }

      .evaluate-item-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 30px;
      }

      .evaluate-item-content-stars {
        display: flex;
      }

      .evaluate-item-content-text {
        margin: 17px 0;
        font-size: 12px;
        font-weight: 500;
        color: #555555;
      }

      .evaluate-item-content-imgs {
        display: flex;
      }

      .evaluate-item-content-img {
        width: 80px;
        height: 80px;
        background: #f0eff4;
        border: 1px solid #e9e9e9;
      }

      .evaluate-item-content-img img {
        width: 100%;
        height: 100%;
      }

      .evaluate-item-content-img + .evaluate-item-content-img {
        margin-left: 15px;
      }

      .evaluate-item-content-actionbar {
        display: flex;
        margin-top: 15px;
        font-size: 12px;
        font-weight: 500;
        color: #888888;
      }
      .evaluate-item-content-actionbar li {
        @include flex-start;
        img {
          vertical-align: middle;
          margin-right: 5px;
        }
      }
      .evaluate-item-content-actionbar li + li {
        margin-right: 15px;
        cursor: pointer;
      }

      .evaluate-item-content-actionbar li:first-child {
        margin-right: auto;
      }
      .author-video {
        padding: 30px 0 30px 0;
      }
      .author-pic {
        display: flex;
        justify-content: space-between;
        padding: 0 0 30px 0;
      }
      .author-pic img {
        border-radius: 10px;
      }
    }
  }
}

.shop_box {
  padding: 0 0 20px 0;
  border-bottom: 1px solid #cccc;
  display: flex;
  // justify-content: center;
  align-items: center;
  .shop_left {
    margin: 0 10px;
    .img {
      width: 60px;
      height: 60px;
    }
  }

  .shop_right {
    display: flex;
    flex: 1;
    flex-direction: column;
    .txet_top {
      color: #000;
      font-size: 16px;
      margin: 0 0 15px 0;
    }

    .text_bottom {
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0 0;
      font-size: 14px;
      .text_left {
        color: #999999;
      }

      .text_center {
        color: #999999;
      }

      .text_right {
        color: #eb441e;
      }
    }
  }
}

.coupons_box {
  padding: 20px 0;
  border-bottom: 1px solid #cccc;
  .coupons_top {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 16px;
    .icon {
      width: 14px;
      height: 10px;
      @include backgroundGroup('../../../../assets/images/coupons.png');
      margin: 0 10px 0 5px;
    }

    .el-input {
      width: 150px;
      height: 30px;
      margin-left: 20px;
      ::v-deep .el-input__inner {
        height: 30px;
      }
    }
  }

  .coupons_bottom {
    margin-top: 15px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 30px;
    .text_left {
      color: #999999;
    }

    .text_right {
      color: #eb441e;
    }
  }
}

.payment_text {
  padding: 20px 0 70px 0;
  width: 100%;
  text-align: right;
  font-size: 14px;
  color: #000;
  .text {
    color: #eb441e;
    margin-right: 15px;
  }
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: right;
  .payment_text {
    padding: 0;
    font-weight: 800;
    font-size: 16px;
  }
}

// 支付弹窗
.payment {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    width: 500px;
    height: 390px;
    background: #ffffff;
    border: 0px solid #818790;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      height: 42px;
      background: #f4f6f7;
      padding: 0;
      line-height: 42px;
      .el-dialog__title {
        margin-left: 15px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 500;
        color: #393939;
      }
      .el-dialog__headerbtn {
        top: $font-size-small;
        right: $font-size-small;
        font-size: $font-size-large;
      }
    }
    .el-dialog__body {
      padding: 65px 20px;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .notes {
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
        }
        .imgBox {
          width: 110px;
          height: 110px;
          margin-top: 30px;
          img {
            width: 110px;
            height: 110px;
          }
        }
        .price {
          margin-top: 30px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
          span {
            color: #eb441e;
            span {
              font-size: $font-size-large;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

// 支付成功弹窗
.paymentSuccess {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    width: 340px;
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      padding: 12px 12px 0px;
      .el-dialog__title {
        font-size: $font-size-small;
        font-family: PingFang SC;
        font-weight: 400;
        color: $color-text-gray;
      }
      .el-dialog__headerbtn {
        top: 12px;
        right: 12px;
      }
    }
    .el-dialog__body {
      padding: 15px 39px;
      .content {
        margin: 40px 0;
        text-align: center;
        font-size: $font-size-medium-x;
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-text-black;
        line-height: 26px;
        span {
          color: $color-bg-heightlight;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        .footer-btn {
          font-size: 18px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-bg-heightlight;
          cursor: pointer;
        }
      }
    }
  }
}

// 回复
// 支付弹窗
.reply {
  ::v-deep .el-dialog {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    width: 40%;

    background: #ffffff;
    border: 0px solid #818790;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
    .el-dialog__header {
      height: 42px;
      background: #ffffff;
      padding: 0;
      line-height: 42px;
      border-bottom: 1px solid #eeeeee;
      .el-dialog__title {
        margin-left: 15px;
        font-size: $font-size-medium;
        font-family: PingFang SC;
        font-weight: 800;
        color: #393939;
      }
      .el-dialog__headerbtn {
        top: $font-size-small;
        right: $font-size-small;
        font-size: $font-size-large;
      }
    }
    .el-dialog__body {
      .content {
        .textarea_box {
          .el-textarea__inner {
            resize: none;
            font-family: PingFang SC;
          }
        }

        .btn {
          margin-top: 20px;
          text-align: right;
          .el-button {
            width: 114px;
          }
        }
      }
    }
  }
}
</style>
